<script setup lang="ts">
import type { PropType } from 'vue';

import { type AuctionAssetBusEntity, type AuctionItemEntity } from '~/apiClient';

// COMPOSABLES
const { tt } = useTypedI18n();
const { locale } = useI18n();

// PROPS
const props = defineProps({
    auction: { type: Object as PropType<AuctionItemEntity>, required: true },
});

// DATA
const mainAsset = computed<AuctionAssetBusEntity>(() => props.auction.mainAsset);
const isBundle = computed<boolean>(() => isAuctionBundle(props.auction));
const hasDetails = computed<boolean>(() => props.auction.assets.some(_a => _a.damageNotes[locale.value] || _a.images.damage.length));
const sortedAssets = computed<AuctionAssetBusEntity[]>(() => sortAuctionAssets(props.auction));
</script>

<template>
    <section v-if="hasDetails" class="flex flex-col gap-6">
        <h2 class="text-lg font-semibold md:text-3xl">{{ tt('adp.detailsTitle', isBundle ? 2 : 1) }}</h2>

        <template v-if="isBundle">
            <template v-for="(asset, index) in sortedAssets" :key="asset.id">
                <BaseAccordion
                    v-if="!!asset.damageNotes[locale] || !!asset.images.damage.length"
                    :ui="{ default: { class: 'font-normal text-base' } }"
                    :items="[{ label: tt('asset.vehicleIndex', { index: index + 1 }), defaultOpen: index === 0 }]"
                >
                    <template #item>
                        <AdpDetailsItem :asset="asset" :index="index + 1" />
                    </template>
                </BaseAccordion>
            </template>
        </template>

        <AdpDetailsItem v-else :asset="mainAsset" />
    </section>
</template>
