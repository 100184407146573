<script setup lang="ts">
import { AssetType, type AuctionAssetBusEntity } from '~/apiClient';
import type { Breadcrumb } from '~/types/breadcrumb.type';

// PROPS
const props = defineProps({
    asset: { type: Object as PropType<AuctionAssetBusEntity>, required: true },
});

// COMPOSABLES
const { tt } = useTypedI18n();
const { formatAlpRoute } = useAlpRouteUtils();

// DATA
const breadCrumbs = computed<Breadcrumb[] | undefined>(
    () =>
        props.asset && [
            {
                label: tt('homepage.link'),
                to: 'index',
            },
            {
                label: tt(`auctions.page.asset.${AssetType.Bus}`),
                to: formatAlpRoute({ assetType: AssetType.Bus }),
            },
            {
                label: tt(`asset.class.${props.asset!.class[0]}`),
                to: formatAlpRoute({ assetType: AssetType.Bus, assetClass: props.asset!.class[0] }),
            },
            {
                label: props.asset.brand.brandValue,
                to: formatAlpRoute({
                    assetType: AssetType.Bus,
                    assetClass: props.asset!.class[0],
                    brandUrlKey: props.asset!.brand.urlKey,
                }),
            },
            {
                label: props.asset!.model.modelValue,
                to: formatAlpRoute({
                    assetType: AssetType.Bus,
                    assetClass: props.asset.class[0],
                    brandUrlKey: props.asset.brand.urlKey,
                    modelUrlKey: props.asset.model.urlKey,
                }),
            },
        ]
);
</script>

<template>
    <BaseBreadcrumbs v-if="breadCrumbs" class="text-neutral-500" :items="breadCrumbs" />
</template>
