<script setup lang="ts">
import type { AccordionItem } from '#ui/types';
import type { PropType } from 'vue';

import type { AssetSpecsSection } from '~/types/assetSpecs.type';

// PROPS
const props = defineProps({
    title: { type: String as PropType<TypedTranslateReturn>, required: true },
    defaultOpen: { type: Boolean, default: false },
    isBundle: { type: Boolean, default: false },
    grid: { type: Boolean, default: false },
    specs: { type: Array as PropType<AssetSpecsSection>, required: true },
});

// COMPOSABLES
const { tt } = useTypedI18n();

// DATA
const isNotEmpty = computed<boolean>(() => props.specs.some(group => group.some(spec => spec[1] !== null)));
const accordionItems = computed<AccordionItem[]>(() => [{ label: props.title, defaultOpen: props.defaultOpen }]);
</script>

<template>
    <BaseAccordion v-if="isNotEmpty" :ui="{ default: { class: 'font-normal text-xl' } }" :items="accordionItems" :class="$style['accordion']">
        <template #item>
            <div class="mb-3 flex flex-col gap-6" :class="grid && 'md:grid md:grid-cols-2'">
                <template v-for="(group, i) in specs" :key="i">
                    <div v-if="group.some(spec => spec[1] !== null)" class="flex flex-col gap-0">
                        <h3 v-if="isBundle" class="text-base font-medium leading-6">{{ tt('asset.vehicleIndex', { index: i + 1 }) }}</h3>
                        <AdpSpecItem v-for="(spec, j) in group" :key="j" :name="tt(spec[0])" :value="spec[1]" />
                    </div>
                </template>
            </div>
        </template>
    </BaseAccordion>
</template>
<style scoped module lang="css">
.accordion {
    :deep([data-headlessui-state='open']) {
        @apply border-primary-500 transition-colors;
        button {
            @apply text-primary	transition-colors;
        }
    }
}
</style>
