<script setup lang="ts">
import { countries } from '@lib/shared';
import lodashSortBy from 'lodash-es/sortBy';
import lodashToNumber from 'lodash-es/toNumber';
import lodashToString from 'lodash-es/toString';
import type { PropType } from 'vue';

import { AssetStateOfUse, type AuctionAssetBusEntity, type AuctionItemEntity } from '~/apiClient';
import type { AssetSpecItem, AssetSpecsGroup, AssetSpecsSection } from '~/types/assetSpecs.type';

// PROPS
const props = defineProps({
    auction: { type: Object as PropType<AuctionItemEntity>, required: true },
});

// COMPOSABLES
const { tt, formatUnits, td } = useTypedI18n();
const { locale } = useTypedI18n();

// COMPUTED
const mainAsset = computed<AuctionAssetBusEntity>(() => props.auction.mainAsset);
const isBundle = computed<boolean>(() => isAuctionBundle(props.auction));
const sortedAssets = computed<AuctionAssetBusEntity[]>(() => sortAuctionAssets(props.auction));

const bundleSpecs = computed<AssetSpecsSection>(() =>
    sortedAssets.value.map(_a => [
        ['asset.firstRegistration', td(_a.firstRegistration, 'mmyyyy')],
        ['asset.kilometers', formatUnits.km(_a.km)],
        ['asset.vehicleCondition.base', tt(`asset.vehicleCondition.${_a.vehicleCondition}`)],
        ...insertIf<AssetSpecItem>(!!_a.useType, () => ['asset.useType.base', tt(`asset.useType.${_a.useType}`)]),
        ['asset.vin', _a.vin],
        ['asset.inspectionExpiryDate', td(_a.inspectionExpiryDate, 'mmyyyy')],
        ['asset.registrationDocumentCountry', getCountryTranslation(_a)],
    ])
);
const descriptionSpecs = computed<AssetSpecsSection>(() => [
    ...insertIf<AssetSpecsGroup>(
        isBundle.value,
        [
            ['asset.antiPollution', tt(`asset.euroNorm.${mainAsset.value.euroNorm}`)],
            ['asset.class.base', mainAsset.value.class.map(busClass => tt(`asset.class.${busClass}`)).join(', ')],
            ['asset.version', mainAsset.value.version.versionValue],
            ['asset.geographicalLocation', mainAsset.value.geographicalLocation],
            [
                'asset.visitDate.base',
                mainAsset.value.visitDateFrom && mainAsset.value.visitDateTo
                    ? tt('asset.visitDate.fromTo', {
                          date: td(mainAsset.value.visitDateFrom, 'ddmmyyyy'),
                          fromTime: td(removeTimezone(mainAsset.value.visitDateFrom), 'hh'),
                          toTime: td(removeTimezone(mainAsset.value.visitDateTo), 'hh'),
                      })
                    : null,
            ],
        ],
        [
            ['asset.converter', mainAsset.value.bodyBuilder],
            ['asset.deckType.base', tt(`asset.deckType.${mainAsset.value.deckType}`)],
            ['asset.length', formatUnits.meters(mainAsset.value.length)],
            ['asset.width', formatUnits.meters(mainAsset.value.width)],
            ['asset.height', formatUnits.meters(mainAsset.value.height)],
        ]
    ),
    ...insertIf<AssetSpecsGroup>(
        !isBundle.value,
        [
            ['asset.firstRegistration', td(mainAsset.value.firstRegistration, 'mmyyyy')],
            ['asset.kilometers', formatUnits.km(mainAsset.value.km)],
            ['asset.antiPollution', tt(`asset.euroNorm.${mainAsset.value.euroNorm}`)],
            ['asset.class.base', mainAsset.value.class.map(busClass => tt(`asset.class.${busClass}`)).join(', ')],
            ['asset.version', mainAsset.value.version.versionValue],
            ['asset.geographicalLocation', mainAsset.value.geographicalLocation],
            [
                'asset.visitDate.base',
                mainAsset.value.visitDateFrom && mainAsset.value.visitDateTo
                    ? tt('asset.visitDate.fromTo', {
                          date: td(mainAsset.value.visitDateFrom, 'ddmmyyyy'),
                          fromTime: td(removeTimezone(mainAsset.value.visitDateFrom), 'hh'),
                          toTime: td(removeTimezone(mainAsset.value.visitDateTo), 'hh'),
                      })
                    : null,
            ],
        ],
        [
            ['asset.vehicleCondition.base', tt(`asset.vehicleCondition.${mainAsset.value.vehicleCondition}`)],
            ...insertIf<AssetSpecItem>(!!mainAsset.value.useType, () => ['asset.useType.base', tt(`asset.useType.${mainAsset.value.useType}`)]),
            ['asset.vin', mainAsset.value.vin],
            ['asset.converter', mainAsset.value.bodyBuilder],
            ['asset.deckType.base', tt(`asset.deckType.${mainAsset.value.deckType}`)],
            ['asset.length', formatUnits.meters(mainAsset.value.length)],
            ['asset.width', formatUnits.meters(mainAsset.value.width)],
            ['asset.height', formatUnits.meters(mainAsset.value.height)],
            ['asset.inspectionExpiryDate', td(mainAsset.value.inspectionExpiryDate, 'mmyyyy')],
            ['asset.registrationDocumentCountry', getCountryTranslation(mainAsset.value)],
        ]
    ),
    [
        ['asset.doors.base', tt(`asset.doors.${mainAsset.value.doors}`)],
        ['asset.totalCapacity', mainAsset.value.totalCapacity],
        ['asset.seats', mainAsset.value.seats],
        ['asset.seatsFromS1', mainAsset.value.seatsFromS1],
        ['asset.standingPlaces', mainAsset.value.standingPlaces],
        ['asset.standingPlacesFromS2', mainAsset.value.standingPlacesFromS2],
        ['asset.companionSeats', mainAsset.value.companionSeats],
        ['asset.foldingSeats', mainAsset.value.foldingSeats],
        ['asset.wheelchairPlaces', mainAsset.value.wheelchairPlaces],
        ['asset.wheelchairRamp.base', tt(`asset.wheelchairRamp.${mainAsset.value.wheelchairRamp}`)],
    ],
    sortFalseLast([
        ['asset.luggageCapacity', formatUnits.cubicMeters(lodashToNumber(mainAsset.value.luggageCapacity))],
        ['asset.passThroughLuggageCompartment', mainAsset.value.passThroughLuggageCompartment],
    ]),
]);

const conditionsSpecs = computed<AssetSpecsSection>(() =>
    isBundle.value ? sortedAssets.value.map(_a => getConditionSpecs(_a)) : [getConditionSpecs(mainAsset.value)]
);

const transmissionSpecs = computed<AssetSpecsSection>(() => [
    [
        ['asset.gearboxType.base', tt(`asset.gearboxType.${mainAsset.value.gearboxType}`)],
        ['asset.gearboxBrand', mainAsset.value.gearboxBrand],
        ['asset.gearboxModel', mainAsset.value.gearboxModel],
    ],
]);

const engineSpecs = computed<AssetSpecsSection>(() => [
    [
        ['asset.fuel.base', tt(`asset.fuel.${mainAsset.value.fuel}`)],
        ['asset.engineBrand', mainAsset.value.engineBrand],
        ['asset.engineModel', mainAsset.value.engineModel],
        ['asset.enginePosition', tt(`asset.position.${mainAsset.value.enginePosition}`)],
    ],
    [
        ['asset.displacement', formatUnits.cc(mainAsset.value.displacement)],
        ['asset.hp', mainAsset.value.hp],
        ['asset.kw', formatUnits.kw(mainAsset.value.kw)],
        ['asset.cylinders', mainAsset.value.cylinders],
    ],
    [
        ['asset.fuelTankCapacity', formatUnits.liters(mainAsset.value.fuelTankCapacity)],
        ['asset.gasTanks', mainAsset.value.gasTanks],
        ...insertIf<AssetSpecItem>(!!mainAsset.value.gasTanksMaterial, () => [
            'asset.gasTanksMaterial.base',
            tt(`asset.gasTanksMaterial.${mainAsset.value.gasTanksMaterial!}`),
        ]),
        ['asset.batteryPower', formatUnits.kwh(mainAsset.value.batteryPower)],
        ['asset.chargingType', mainAsset.value.chargingType],
    ],
]);

const wheelsSpecs = computed<AssetSpecsSection>(() => [
    sortFalseLast([
        ['asset.axles', mainAsset.value.axles],
        ['asset.wheels', mainAsset.value.wheels],
        ['asset.wheelsSizeAxle1', mainAsset.value.wheelsSizeAxle1],
        ['asset.wheelsSizeAxle2', mainAsset.value.wheelsSizeAxle2],
        ['asset.alloyWheels', mainAsset.value.alloyWheels],
    ]),
]);

const accessoriesSpecs = computed<AssetSpecsSection>(() => [
    sortFalseLast([
        ['asset.acDriver', mainAsset.value.acDriver],
        ['asset.acPassengers', mainAsset.value.acPassengers],
        ['asset.heater', mainAsset.value.heater],
        ['asset.preHeater', mainAsset.value.preHeater],
    ]),
    sortFalseLast([
        ['asset.cruiseControl.base', tt(`asset.cruiseControl.${mainAsset.value.cruiseControl}`)],
        ['asset.tachograph.base', tt(`asset.tachograph.${mainAsset.value.tachograph}`)],
        ['asset.abs', mainAsset.value.abs],
        ['asset.asr', mainAsset.value.asr],
        ['asset.retarder', mainAsset.value.retarder],
        ['asset.breathalyzer', mainAsset.value.breathalyzer],
    ]),
    sortFalseLast([
        ['asset.cctv', mainAsset.value.cctv],
        ['asset.internalCameras', mainAsset.value.internalCameras],
        ['asset.externalCameras', mainAsset.value.externalCameras],
        ['asset.retrocamera', mainAsset.value.retrocamera],
    ]),
    [
        ['asset.firePreventionSystem', mainAsset.value.firePreventionSystem],
        ['asset.firePreventionSystemBrand', mainAsset.value.firePreventionSystemBrand],
    ],
    [
        ['asset.indicatorSigns', mainAsset.value.indicatorSigns],
        ['asset.indicatorSignsBrand', mainAsset.value.indicatorSignsBrand],
    ],
    sortFalseLast([
        ['asset.driverBerth', mainAsset.value.driverBerth],
        ['asset.socket220', mainAsset.value.socket220],
    ]),
    sortFalseLast([
        ['asset.wifi', mainAsset.value.wifi],
        ['asset.microphone', mainAsset.value.microphone],
        ['asset.cdPlayer', mainAsset.value.cdPlayer],
        ['asset.dvdPlayer', mainAsset.value.dvdPlayer],
        ['asset.usbPlayer', mainAsset.value.usbPlayer],
        ['asset.monitors', mainAsset.value.monitors],
        ['asset.mp3', mainAsset.value.mp3],
    ]),
    sortFalseLast([
        ['asset.kitchen', mainAsset.value.kitchen],
        ['asset.coffeeMachine', mainAsset.value.coffeeMachine],
        ['asset.refrigerator', mainAsset.value.refrigerator],
        ['asset.toilet', mainAsset.value.toilet],
    ]),
    sortFalseLast([
        ...insertIf<AssetSpecItem>(!!mainAsset.value.glasses, () => ['asset.glasses.base', tt(`asset.glasses.${mainAsset.value.glasses!}`)]),
        ['asset.openableWindows', mainAsset.value.openableWindows],
        ['asset.curtains', mainAsset.value.curtains],
    ]),
    sortFalseLast([
        ['asset.seatsMonitor', mainAsset.value.seatsMonitor],
        ['asset.seatsRadio', mainAsset.value.seatsRadio],
        ['asset.seatsUsbPorts', mainAsset.value.seatsUsbPorts],
        ['asset.seatsSocket220', mainAsset.value.seatsSocket220],
    ]),
    sortFalseLast([
        ['asset.seatUpholstery.base', tt(`asset.seatUpholstery.${mainAsset.value.seatUpholstery}`)],
        ['asset.recliningSeats', mainAsset.value.recliningSeats],
        ['asset.extendableSeats', mainAsset.value.extendableSeats],
    ]),
    sortFalseLast([
        ['asset.safetyBelts', mainAsset.value.safetyBelts],
        ['asset.coffeeTables', mainAsset.value.coffeeTables],
        ['asset.seatPocket', mainAsset.value.seatPocket],
        ['asset.handRest', mainAsset.value.handRest],
        ['asset.footRest', mainAsset.value.footRest],
        ['asset.hatBox', mainAsset.value.hatBox],
    ]),
]);

// METHODS
const getCountryTranslation = (_a: AuctionAssetBusEntity): string | null =>
    countries.find(_c => _c.countryCode === _a.registrationDocumentCountry)?.i18nNames?.[locale.value] ?? null;

const getConditionSpecs = (_a: AuctionAssetBusEntity): AssetSpecsGroup => [
    ...insertAssetPart(_a.enginePresent, _a.stateOfUseEngine, 'asset.engine'),
    ...insertAssetPart(_a.gearboxPresent, _a.stateOfUseGearbox, 'asset.transmission'),
    ...insertIf<AssetSpecItem>(!!_a.stateOfUseAirConditioner, () => [
        'asset.stateOfUseAirConditioner',
        tt(`asset.stateOfUse.${_a.stateOfUseAirConditioner}`),
    ]),
    ...insertIf<AssetSpecItem>(!!_a.stateOfUseBreakingSystem, () => [
        'asset.stateOfUseBreakingSystem',
        tt(`asset.stateOfUse.${_a.stateOfUseBreakingSystem}`),
    ]),
    ...insertIf<AssetSpecItem>(!!_a.stateOfUseDrivingOrgans, () => [
        'asset.stateOfUseDrivingOrgans',
        tt(`asset.stateOfUse.${_a.stateOfUseDrivingOrgans}`),
    ]),
    ...insertIf<AssetSpecItem>(!!_a.stateOfUsePowerSystem, () => ['asset.stateOfUsePowerSystem', tt(`asset.stateOfUse.${_a.stateOfUsePowerSystem}`)]),
    ...insertIf<AssetSpecItem>(!!_a.stateOfUseTimingBelt, () => ['asset.stateOfUseTimingBelt', tt(`asset.stateOfUse.${_a.stateOfUseTimingBelt}`)]),
    ...insertIf<AssetSpecItem>(!!_a.stateOfUseTransmissionDevices, () => [
        'asset.stateOfUseTransmissionDevices',
        tt(`asset.stateOfUse.${_a.stateOfUseTransmissionDevices}`),
    ]),
    ...insertIf<AssetSpecItem>(!!_a.stateOfUseTurbine, () => ['asset.stateOfUseTurbine', tt(`asset.stateOfUse.${_a.stateOfUseTurbine}`)]),
    ['asset.missingParts', lodashToString(_a.missingParts[locale.value]) || null],
];

const insertAssetPart = (present: boolean | null, stateOfUse: AssetStateOfUse | null, label: TranslationKey): AssetSpecItem[] => {
    if (present === false) return [[label, tt('asset.stateOfUse.missing')]];

    if (stateOfUse) return [[label, tt(`asset.stateOfUse.${stateOfUse}`)]];

    return [];
};

const removeTimezone = (date: string): string => date.replace(/Z$/, '');

const sortFalseLast = (items: AssetSpecItem[]): AssetSpecItem[] =>
    lodashSortBy(items, [(a): boolean | number => (typeof a[1] === 'boolean' ? !a[1] : 0)]);
</script>

<template>
    <section class="flex flex-col gap-6">
        <div class="flex flex-col gap-6">
            <h3 class="text-2xl font-semibold">
                <template v-if="isBundle">{{ tt('asset.bundleSpecs') }}</template>
                <template v-else>{{ tt('asset.vehicleSpecs') }}</template>
            </h3>
            <p v-if="!!mainAsset.additionalNotes[locale]">{{ mainAsset.additionalNotes[locale] }}</p>
        </div>

        <AdpSpecsSection v-if="isBundle" :title="tt('asset.vehiclesInBundle')" :specs="bundleSpecs" default-open is-bundle grid />

        <AdpSpecsSection :title="tt('asset.description')" :specs="descriptionSpecs" :default-open="!isBundle" grid />

        <AdpSpecsSection :title="tt('asset.conditionsAndMaintenance')" :specs="conditionsSpecs" :is-bundle="isBundle" />

        <AdpSpecsSection :title="tt('asset.engine')" :specs="engineSpecs" grid />

        <AdpSpecsSection :title="tt('asset.transmission')" :specs="transmissionSpecs" grid />

        <AdpSpecsSection :title="tt('asset.axlesTires')" :specs="wheelsSpecs" grid />

        <AdpSpecsSection :title="tt('asset.accessories')" :specs="accessoriesSpecs" grid />

        <AdpDocuments :asset="mainAsset" />
    </section>
</template>
