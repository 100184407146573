<script setup lang="ts">
const { tt } = useTypedI18n();
const localePath = useLocalePath();
</script>

<template>
    <i18n-t scope="global" tag="p" class="text-neutral-500" keypath="auction.auctionPanel.feeNotice">
        <NuxtLinkLocale :to="localePath('pricing-buyer')" class="whitespace-nowrap text-black underline" target="_blank">
            {{ tt('auction.auctionPanel.feeNoticeLink') }}
        </NuxtLinkLocale>
    </i18n-t>
</template>
