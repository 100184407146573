<script setup lang="ts">
import { type AuctionAssetBusEntity, BusClass } from '~/apiClient';

// PROPS
defineProps({
    asset: { type: Object as PropType<AuctionAssetBusEntity>, required: true },
});

// COMPOSABLES
const { tt } = useTypedI18n();
</script>

<template>
    <section class="flex flex-col gap-y-3">
        <p class="text-sm sm:text-lg">{{ asset.class.map((cl: BusClass) => tt(`asset.class.${cl}`)).join(', ') }}</p>
        <h1 class="text-3xl font-medium sm:text-5xl">{{ asset.brand.brandValue }} {{ asset.model.modelValue }}</h1>
    </section>
</template>
