<script setup lang="ts">
import { type AuctionItemEntity } from '~/apiClient';

// PROPS
const props = defineProps({
    auction: { type: Object as PropType<AuctionItemEntity>, required: true },
});

// COMPOSABLES
const { tt, td, formatUnits } = useTypedI18n();

// COMPUTED
const firstRegistration = computed(() =>
    props.auction.mainAsset.firstRegistration ? Date.parse(props.auction.mainAsset.firstRegistration) : undefined
);
</script>

<template>
    <div class="flex flex-wrap gap-3">
        <AuctionBadges :auction="auction" />
        <UBadge v-if="firstRegistration" color="fl-dark-blue" variant="solid" size="lg" class="flex gap-x-2">
            <UIcon class="h-5 w-5" name="i-mdi-bus" />
            {{ tt('asset.firstRegistration') }}: {{ td(firstRegistration, 'mmyyyy') }}
        </UBadge>
        <UBadge color="fl-dark-blue" variant="solid" size="lg" class="flex gap-x-2">
            <UIcon class="h-5 w-5" name="i-mdi-gauge" />
            {{ formatUnits.km(auction.mainAsset.km) }}
        </UBadge>
        <UBadge color="fl-dark-blue" variant="solid" size="lg" class="flex gap-x-2">
            <UIcon class="h-5 w-5" name="i-mdi-engine" />
            {{ tt(`asset.euroNorm.${auction.mainAsset.euroNorm}`) }}
        </UBadge>
    </div>
</template>
